<template>
  <div class="submit-form">
    <div v-if="!submitted">
      <div class="form-group">
        <label for="libelle">Libellé</label>
        <input
          type="text"
          class="form-control"
          id="libelle"
          required
          v-model="mariage.libelle"
          name="libelle"
        />
      </div>

      <div class="form-group">
        <label for="dateDot">Date de Dot</label>
        <input
          type="dateDot"
          class="form-control"
          id="dateDot"
          required
          v-model="mariage.dateDot"
          name="dateDot"
        />
      </div>

      <div class="form-group">
        <label for="lieuDot">Lieu de Dot</label>
        <input
          type="text"
          class="form-control"
          id="lieuDot"
          required
          v-model="mariage.lieuDot"
          name="lieuDot"
        />
      </div>

      <div class="form-group">
        <label for="dateCivil">Date de Civil</label>
        <input
          type="dateCivil"
          class="form-control"
          id="dateCivil"
          required
          v-model="mariage.dateCivil"
          name="dateCivil"
        />
      </div>

      <div class="form-group">
        <label for="lieuCivil">Lieu de Civil</label>
        <input
          type="text"
          class="form-control"
          id="lieuCivil"
          required
          v-model="mariage.lieuCivil"
          name="lieuCivil"
        />
      </div>

      <div class="form-group">
        <label for="dateReligieux">Date de religieux</label>
        <input
          type="dateReligieux"
          class="form-control"
          id="dateReligieux"
          required
          v-model="mariage.dateReligieux"
          name="dateReligieux"
        />
      </div>

      <div class="form-group">
        <label for="lieuReligieux">Lieu de Religieux</label>
        <input
          type="text"
          class="form-control"
          id="lieuReligieux"
          required
          v-model="mariage.lieuReligieux"
          name="lieuReligieux"
        />
      </div>

      <div class="form-group">
        <label for="user">Utilisateur</label>
        <input
          class="form-control"
          id="user"
          value="{{currentUser}}"
          required
          v-model="mariage.user"
          name="user"
        />
      </div>
      

      <button @click="saveMariage" class="btn btn-success">Valider</button>
    </div>

    <div v-else>
      <h4>Mariage ajouté avec succès !</h4>
      <button class="btn btn-success" @click="newMariage">Ajouter</button>
    </div>
  </div>
</template>

<script>
import MariageDataService from "../services/MariageDataService";

export default {
  name: "add-mariage",
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  data() {
    return {
      mariage: {
        id: null,
        lieuDot: "",
        dateDot: null,
        lieuCivil: "",
        dateCivil: null,
        lieuReligieux: "",
        dateReligieux: null,
        estDotConfirme: false,
        estCivilConfirme: false,
        estReligieuxConfirme: false
      },
      submitted: false
    };
  },
  methods: {
    saveMariage() {
      var data = {
        lieuDot: this.mariage.lieuDot,
        dateDot: this.mariage.dateDot,
        lieuCivil: this.mariage.lieuCivil,
        dateCivil: this.mariage.dateCivil,
        lieuReligieux: this.mariage.lieuReligieux,
        dateReligieux: this.mariage.dateReligieux,
        user: this.mariage.user
      };

      MariageDataService.create(data)
        .then(response => {
          this.mariage.id = response.data.id;
          console.log(response.data);
          this.submitted = true;
        })
        .catch(e => {
          console.log(e);
        });
    },
    
    newMariage() {
      this.submitted = false;
      this.mariage = {};
    }
  }
};
</script>

<style>
.submit-form {
  max-width: 300px;
  margin: auto;
}
</style>